/**
 * Written by Linh
 */

import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactPlayer from "react-player";
import useSound from "use-sound";

import "../../assets/styles/selection-styles.css";
import * as link from "../../ultilities/global.links.js";
import { playAudio, stopAudio } from "../../ultilities/redux.audioSlice.js";
import { CorrectPopUp, IncorrectPopUp } from "../display/PopUp";

export default function MCVerticalRight({
  isSpanish,
  options,
  message,
  audioSource,
  setSelectorReturnValue,
}) {
  // for audio
  const dispatch = useDispatch();
  const correctVORef = useRef(null);
  const incorrectVORef = useRef(null);
  const playing = useSelector((state) => state.audio.playing);

  // for popup and selection
  const [selectedOption, setSelectedOption] = useState(-1);
  const [showCorrectPopup, setShowCorrectPopup] = useState(false);
  const [showIncorrectPopup, setShowIncorrectPopup] = useState(false);

  // sounds effects for popup
  const [playCorrectSFX] = useSound(link.CORRECT_SOUND, {
    volume: 0.25,
    loop: false,
  });
  const [playIncorrectSFX] = useSound(link.INCORRECT_SOUND, {
    volume: 0.25,
    loop: false,
  });

  const textLen = options[0].text.length;

  function handleClick(option, index) {
    if (option === "correctAnswer") {
      // popup handle the correct answer
      setShowCorrectPopup(true);
      setSelectedOption(index);

      // audio handles
      // stop slide vo and update redux state for feedback VO
      playCorrectSFX();

      if (playing === "slideVO") {
        dispatch(stopAudio());
        dispatch(playAudio({ id: "correctFeedbackVO" }));
      }
    } else {
      setShowIncorrectPopup(true);

      // audio handles
      // stop slide vo and update redux state for feedback VO
      playIncorrectSFX();

      if (playing === "slideVO") {
        dispatch(stopAudio());
        dispatch(playAudio({ id: "incorrectFeedbackVO" }));
      }
    }
  }

  useEffect(() => {
    setSelectedOption(null);
    setShowCorrectPopup(false);
    setShowIncorrectPopup(false);
  }, [options]);

  return (
    <div>
      <div
        className={`${textLen >= 25 ? `mc-container-half` : ""} ${textLen < 15 ? `mc-container-third` : ""} mc-container`}
      >
        {options !== undefined ? (
          options.map((option, index) => (
            <button
              className={`mc-btn ${isSpanish ? "es-mc-btn" : ""} ${
                selectedOption === index ? "correct-btn" : ""
              } ${
                selectedOption !== null && selectedOption !== index
                  ? "inactive-btn"
                  : ""
              }`}
              disabled={selectedOption !== null && selectedOption !== index}
              onClick={() => {
                handleClick(option.name, index);
              }}
            >
              {isSpanish ? option.textEs : option.text}
            </button>
          ))
        ) : (
          <></>
        )}
      </div>
      {/* pop up for correct/incorrect answers */}
      {/* setSelectorReturnValue determines if the answer is correct or incorrect */}
      {showCorrectPopup && (
        <CorrectPopUp
          isSpanish={isSpanish}
          message={message}
          setShowPopup={setShowCorrectPopup}
          setSelectorReturnValue={setSelectorReturnValue}
        />
      )}
      {showIncorrectPopup && (
        <IncorrectPopUp
          isSpanish={isSpanish}
          setShowPopup={setShowIncorrectPopup}
        />
      )}

      {/* Feedback VO triggered when redux state is updated */}
      {audioSource?.length > 0 ? (
        <ReactPlayer
          url={
            isSpanish
              ? link.BACKEND_LINK + audioSource[1]
              : link.BACKEND_LINK + audioSource[0]
          }
          playing={playing === "correctFeedbackVO"}
          ref={correctVORef}
        />
      ) : (
        <></>
      )}

      {/* default incorrect VO */}
      <ReactPlayer
        url={isSpanish ? link.INCORRECT_ES : link.INCORRECT_EN}
        playing={playing === "incorrectFeedbackVO"}
        ref={incorrectVORef}
      />
    </div>
  );
}
