/**
 * Written by Ronghua
 */

import React, { useCallback, useEffect, useRef } from "react";
import { Box } from "@mui/material";
import Slider from "@mui/joy/Slider";
import { useState } from "react";
import useSound from "use-sound";
import useScreenSize from "../../ultilities/useScreenSize";
import { globalContext } from "../../Context";
import { useContext } from "react";

const sliderTextStyle = {
  display: "flex",
  color: "black",
  fontFamily: "Balsamiq Sans, sans-serif",
  fontWeight: 400,
  textAlign: "center",
  fontSize: "2.4vw",
  position: "absolute",
};

// need height width of the silder
export default function ImageSlider({
  changingImages,
  sliderStyle,
  audioSources,
  setSelectorReturnValue, 
}) {
  const { slideId, setSlideId } = useContext(globalContext);
  // hideNextButton

  const [value, setValue] = useState(0);
  const valueRef = useRef(0); // Track the current playing sound

  const [play, { stop }] = useSound(
    process.env.REACT_APP_BACKEND_HOST + audioSources[valueRef.current],
    { interrupt: true }
  );
  const screenSize = useScreenSize();

  useEffect(() => {
    play();
  }, [valueRef.current]);

  function handleChange(e) {
    valueRef.current = e.target.value;
    setValue(e.target.value);
    if (value === 3) {
      setSelectorReturnValue("turnOnNextBtn");
    }
    updateValue();
  }

  const updateValue = useCallback(() => {
    stop();
  }, [valueRef.current]);

  useEffect(() => {
    stop();
  }, [slideId]);

  return (
    <Box>
      {changingImages.map((items) => (
        <Box>
          <Box>
            <img
              src={
                process.env.REACT_APP_BACKEND_HOST +
                items.media[valueRef.current]
              }
              style={{
                objectFit: "contain",
                width: (items.width / 832) * screenSize.height + "px",
                left:
                  (items.left[valueRef.current] / 1280) * screenSize.width +
                  "px",
                top: (items.top / 832) * screenSize.height + "px",
                position: "absolute",
              }}
              alt=""
            ></img>
          </Box>
        </Box>
      ))}
      <Box
        sx={{
          ...sliderTextStyle,
          width: (sliderStyle.width / 1280) * screenSize.width + "px",
          left: (sliderStyle.left / 1280) * screenSize.height + "px",
          top: (sliderStyle.top / 1280) * screenSize.width + "px",
        }}
      >
        <Slider
          defaultValue={0}
          min={0}
          max={4}
          step={1}
          disableRipple
          valueLabelDisplay="off"
          variant="soft"
          onChange={handleChange}
          disabled={value == 4}
          sx={{
            "--Slider-trackSize": screenSize.height * 0.02 + "px",
            "--Slider-thumbSize": screenSize.height * 0.06 + "px",

            "& .MuiSlider-thumb": {
              background: "#FFFFFF",
              border: screenSize.width * 0.003 + "px solid black",
            },
            "& .MuiSlider-track": {
              background: "#EC4511",
              border: screenSize.width * 0.003 + "px solid black",
            },
            "& .MuiSlider-rail": {
              background: "#FFEB34",
              border: screenSize.width * 0.003 + "px solid black",
            },
          }}
        />
      </Box>
    </Box>
  );
}
