/**
 * Written by Linh and Ronghua
 */

import { useNavigate } from "react-router-dom";

import tutorialIcon from "../../assets/icons/UI_tutorial_icon.png";
import backpackIcon from "../../assets/icons/UI_Backpack.png";
import profileBadgeIcon from "../../assets/icons/UI_Badge_Profile_icon.png";
import moduleIcon from "../../assets/icons/UI_Modules_Icon.png";
import settingIcon from "../../assets/icons/UI_Settings_Icon.png";
import tocIcon from "../../assets/icons/UI_TOC_Icon.png";

import PreviousArrowButton from "../../components/backpacks/BackArrowButton";
import GreenTitleBar from "../../components/backpacks/GreenTitleBar";

import "../../assets/styles/lesson-selection-styles.css";
import "../../assets/styles/auth-styles.css";
import "../../assets/styles/backgrounds-styles.css";
import ContentListing from "../../components/backpacks/ContentListing.js";

export default function Backpack({
  showTutorial,
  tutorialHighlighted,
  badgeProfileHighlighted,
  tocHighlighted,
  modulesHighlighted,
  settingsHighlighted,
  signOutHighlighted,
}) {
  const navigate = useNavigate();
  const iconData = [
    {
      img: tutorialIcon,
      title: "Tutorial",
      highlighted: tutorialHighlighted, // edit this later
    },
    // {
    //   img: profileBadgeIcon,
    //   title: "Badges and\nProfile",
    //   highlighted: badgeProfileHighlighted,
    // },
    {
      img: tocIcon,
      title: "Table of\nContents",
      highlighted: tocHighlighted,
    },
    {
      img: moduleIcon,
      title: "Modules",
      highlighted: modulesHighlighted,
    },
    {
      img: settingIcon,
      title: "Settings",
      highlighted: settingsHighlighted,
    },
  ];

  // used to give coresponding url for each item shown in backpack
  const iconLinks = [
    "/tutorialPage",
    // "/badgeAndProfile",
    "/TOCPage",
    "/modulePage",
    "/setting",
  ];

  const handleIconClicked = (index) => {
    const destination = iconLinks[index];
    navigate(destination);
  };

  const handleSignOutPressed = () => {
    navigate("/landingPage");
  };

  return (
    <div className="background high-grass-bg">
      <div className="scrollable-page-body">
        {!showTutorial && <PreviousArrowButton showLeftButton={true} />}
        <GreenTitleBar
          icon={backpackIcon}
          title={"Backpack"}
          boxWidth={"27%"}
          topMargin={showTutorial ? "8vh" : null}
        />

        <div
          className={`scrollable-item-selection ${
            showTutorial ? "module-tutorial" : ""
          }`}
        >
          {/* TODO Linh idk what the sytax is: style={{...scrollableLessonsStyle, ...(showTutorial && forTutoriaStyle)}}> */}
          {iconData.map((items, index) => (
            // show list of lesson and its icon + title
            <ContentListing
              completed={false} // TODO: completed whether the player has finished the lesson, its not implemented yet
              available={true}
              nameOfTopic={items.title}
              lessonThumbnail={items.img}
              scrollingCheck={true}
              modulesCheck={false}
              backpack={true}
              handleButtonClick={() => {
                handleIconClicked(index);
              }}
            />
          ))}
        </div>

        {/* signout button */}
        <button className="auth-btn" onClick={handleSignOutPressed}>
          Sign Out
        </button>
      </div>
    </div>
  );
}
