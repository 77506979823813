/**
 * Written by Ronghua
 */

import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import ProfileImage from "../../assets/characters/C_Volcanologist_Blue_Wave.png";

import * as styles from "../../assets/ts-styles/character-select-styles.ts";

import BackArrowButton from "../../components/backpacks/BackArrowButton";
import TitleText from "../../components/backpacks/TitleText";

const characterData = [
  {
    img: ProfileImage,
    title: "Blue",
  },
  {
    img: ProfileImage,
    title: "Pink",
  },
  {
    img: ProfileImage,
    title: "Green",
  },
  {
    img: ProfileImage,
    title: "Blue",
  },
  {
    img: ProfileImage,
    title: "Settings",
  },
];

export default function CharacterSelect({ showTutorial }) {
  const location = useLocation();
  const [unitId, setUnitId] = useState(
    location.state === null ? "6663546d8060991a53417aa3" : location.state.unitId
  );
  const [state, setState] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    fetch(process.env.REACT_APP_BACKEND_HOST + "api/getUnit/" + unitId)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setState(data);
      });
  }, [unitId]);

  return (
    <Box>
      {!showTutorial && (
        <BackArrowButton // TODO not linked correctly
          previousPageName={"landingPage"}
        />
      )}
      <TitleText text={"Characters"} />

      <Box
        sx={{
          ...styles.scrollableLessonsStyle,
          ...(showTutorial && styles.tutorialStyle),
        }}
      >
        {characterData.map((items) => (
          <Box sx={{ display: "flex", border: "3px solid red" }}>
            <Box sx={styles.iconBoxStyle}>
              <img
                src={items.img}
                style={{
                  height: "35vh",
                  margin: "auto",
                  objectFit: "contain",
                }}
                alt="Character"
              ></img>
              <Box>{items.title}</Box>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
}
