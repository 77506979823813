export const parentStyle = {
    border: "1px solid #FFFFFF",
    height: "100%",
    display: "flex",
    width: "100%",
};

export const lessonStyle = {
    padding: "10%",
    textAlign: "center", // everything below is relating to text
    whiteSpace: "pre-wrap",
    color: "black",
    fontFamily: "Fredoka, sans-serif",
    fontSize: "calc(var(--screen-height) * 0.04)",
    fontWeight: 600,
    width: "calc(var(--screen-width) * 0.20)",
};

export const lessonAndLockStyle = {
    top: "calc(var(--screen-height) * 0.05)",
    height: "calc(var(--screen-height) * 0.30)",
    position: "relative",
};

export const lessonIconStyle = {
    height: "calc(var(--screen-height) * 0.23)",
    objectFit: "contain",
    width: "100%",
};

export const lockIconStyle = {
    height: "100%",
    width: "100%",
    position: "absolute",
    top: "0",
    margin: "auto",
};

export const textPositionStyle = {
    top: "calc(var(--screen-height) * 0.01)",
    position: "relative",
};
