/**
 * Written by Ronghua
 */

import { Box } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import lockIcon from "../../assets/icons/UI_Lock_Icon.png";
import * as styles from "../../assets/ts-styles/content-listing.ts";

// TODO: maybe refactor this and TOCPageInstance item into a single thing?
const ContentListing = ({
  completed,
  available,
  nameOfTopic,
  lessonThumbnail,
  scrollingCheck,
  modulesCheck,
  handleButtonClick,
  backpack,
}) => {
  const navigate = useNavigate();
  const handleButtonClickNone = () => {
    console.log("mdddoew");
  };

  return (
    <Box className='parent-style'>
      <Box sx={styles.lessonStyle}>
        <Box
          sx={styles.lessonAndLockStyle}
          onClick={available ? handleButtonClick : handleButtonClickNone}
        >
          <img
            style={{ ...styles.lessonIconStyle, opacity: available ? "1" : ".3" }}
            src={backpack? lessonThumbnail : process.env.REACT_APP_BACKEND_HOST + lessonThumbnail}
            alt="Lesson thumbnail"
          />

          <Box sx={styles.lockIconStyle}>
            {!available && (
              <img style={{ margin: "8vh auto" }} src={lockIcon} alt="icon"/>
            )}
          </Box>
        </Box>
        <Box sx={{ ...styles.textPositionStyle, opacity: available ? "1" : ".3" }}>
          {nameOfTopic}
        </Box>
      </Box>
    </Box>
  );
};

export default ContentListing;
