import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactPlayer from "react-player";
import useSound from "use-sound";

import "../../assets/styles/mc-picture-styles.css";
import { CorrectPopUp, IncorrectPopUp } from "../display/PopUp";
import * as link from "../../ultilities/global.links.js";
import { stopAudio, playAudio } from "../../ultilities/redux.audioSlice";

export default function MCPicture({
  isSpanish,
  options,
  message,
  audioSource,
  setSelectorReturnValue,
}) {
  // for audio
  const dispatch = useDispatch();
  const correctVORef = useRef(null);
  const incorrectVORef = useRef(null);
  const playing = useSelector((state) => state.audio.playing);

  // for popup and selection
  const [selectedOption, setSelectedOption] = useState(null);
  const [showCorrectPopup, setShowCorrectPopup] = useState(false);
  const [showIncorrectPopup, setShowIncorrectPopup] = useState(false);

  // sounds effects for popup
  const [playCorrectSFX] = useSound(link.CORRECT_SOUND, {
    volume: 0.25,
    loop: false,
  });
  const [playIncorrectSFX] = useSound(link.INCORRECT_SOUND, {
    volume: 0.25,
    loop: false,
  });

  // handle pop-up and correct answer btn effect
  function handleClick(option, index) {
    if (option === "correctAnswer") {
      setShowCorrectPopup(true);
      setSelectedOption(index);

      // audio handles
      // stop slide vo and update redux state for feedback VO
      playCorrectSFX();

      if (playing === "slideVO") {
        dispatch(stopAudio());
        dispatch(playAudio({ id: "correctFeedbackVO" }));
      }
    } else {
      setShowIncorrectPopup(true);

      // audio handles
      // stop slide vo and update redux state for feedback VO
      playIncorrectSFX();

      if (playing === "slideVO") {
        dispatch(stopAudio());
        dispatch(playAudio({ id: "incorrectFeedbackVO" }));
      }
    }
  }

  useEffect(() => {
    setSelectedOption(null);
    setShowCorrectPopup(false);
    setShowIncorrectPopup(false);
  }, [options]);

  const optionsContainer =
    options.length === 2 ? "two-options-container" : "picture-btn-container-2";

  return (
    <div>
      <div className="horizontal-mc-picture-container">
        <div className={optionsContainer}>
          {options !== undefined ? (
            options.map((option, index) => (
              <div className="button-img-container" key={index}>
                <button
                  className={`picture-btn-2 ${
                    selectedOption === index ? "correct-btn" : ""
                  } ${
                    selectedOption !== null && selectedOption !== index
                      ? "inactive-btn"
                      : ""
                  }`}
                  disabled={selectedOption !== null && selectedOption !== index}
                  onClick={() => {
                    handleClick(option.name, index);
                  }}
                >
                  {isSpanish ? option.textEs : option.text}
                </button>
                <div className="picture-container-2">
                  <img
                    src={process.env.REACT_APP_BACKEND_HOST + option.media[0]}
                    alt=""
                    onClick={() => {
                      handleClick(option.name, index);
                    }}
                  />
                </div>
              </div>
            ))
          ) : (
            <></>
          )}
        </div>
      </div>
      {showCorrectPopup && (
        <CorrectPopUp
          isSpanish={isSpanish}
          message={message}
          setShowPopup={setShowCorrectPopup}
          setSelectorReturnValue={setSelectorReturnValue}
        />
      )}
      {showIncorrectPopup && (
        <IncorrectPopUp
          isSpanish={isSpanish}
          setShowPopup={setShowIncorrectPopup}
        />
      )}

      {/* Feedback VO triggered when redux state is updated */}
      {audioSource?.length > 0 ? (
        <ReactPlayer
          url={
            isSpanish
              ? link.BACKEND_LINK + audioSource[1]
              : link.BACKEND_LINK + audioSource[0]
          }
          playing={playing === "correctFeedbackVO"}
          ref={correctVORef}
        />
      ) : (
        <></>
      )}

      {/* default incorrect VO */}
      <ReactPlayer
        url={isSpanish ? link.INCORRECT_ES : link.INCORRECT_EN}
        playing={playing === "incorrectFeedbackVO"}
        ref={incorrectVORef}
      />
    </div>
  );
}
