/**
 * Written by Ronghua
 */

import React from "react";
import { Box } from "@mui/material";
import Slider from "@mui/joy/Slider";

import settingIcon from "../../assets/icons/UI_Settings_Icon.png";
import profileIcon from "../../assets/icons/profile-icon.png";
import studentBookIcon from "../../assets/icons/student-book-icon.png";
import schoolIcon from "../../assets/icons/school-icon.png";

import * as styles from "../../assets/ts-styles/setting-styles.ts";
import "../../assets/styles/backgrounds-styles.css";

import GreenTitleBar from "../../components/backpacks/GreenTitleBar";
import PreviousArrowButton from "../../components/backpacks/BackArrowButton";
import highlightedBox from "../../components/backpacks/HighlightBox.js"


// TODO: connect the student info from database and display them here
const InfoButtons = ({ icon, buttonName, highlighted }) => {
  return (
    <Box
      sx={{ ...styles.viewInfoButtonStyle, ...(highlighted && highlightedBox) }}
    >
      <img src={icon} style={styles.iconStyle} alt="Button"></img>
      <Box sx={{ margin: "auto 1vw" }}>{buttonName}</Box>
    </Box>
  );
};

export default function Setting({
  showTutorial,
  audioHighlighted,
  infoButtonHighlighted,
}) {
  return (
    <Box className="background high-grass-bg">
      {!showTutorial && (
        <PreviousArrowButton // TODO: not linked correctly
        />
      )}
      <GreenTitleBar icon={settingIcon} title={"Settings"} boxWidth={"25%"} />

      {/* slider for volume master */}
      <Box
        sx={{
          ...styles.sliderTextStyle,
          ...(audioHighlighted && highlightedBox),
        }}
      >
        Master Volume
        <Slider
          defaultValue={2}
          min={0}
          max={10}
          disableRipple
          valueLabelDisplay="off"
          variant="soft"
          sx={{
            "--Slider-trackSize": "2vh",
            "--Slider-thumbSize": "6vh",

            "& .MuiSlider-thumb": {
              background: "#A0E4F6",
              border: ".3vw solid black",
            },
            "& .MuiSlider-track": {
              background: "#C2D2A3",
              border: ".3vw solid black",
            },
            "& .MuiSlider-rail": {
              background: "#E5E5E5",
              border: ".3vw solid black",
            },
          }}
        />
      </Box>

      {/* student info */}
      <Box sx={styles.horzButtonsParent}>
        <InfoButtons
          icon={profileIcon}
          buttonName={"First Name"}
          highlighted={infoButtonHighlighted}
        />

        <InfoButtons
          icon={profileIcon}
          buttonName={"Last Name"}
          highlighted={infoButtonHighlighted}
        />
      </Box>

      <Box sx={styles.horzButtonsParent}>
        <InfoButtons
          icon={schoolIcon}
          buttonName={"School Name"}
          highlighted={infoButtonHighlighted}
        />

        <InfoButtons
          icon={studentBookIcon}
          buttonName={"Student ID"}
          highlighted={infoButtonHighlighted}
        />
      </Box>
    </Box>
  );
}
