/**
 * Written by Ronghua
 */

import React from "react";

import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabList from "@mui/lab/TabList";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";

import ProfileImage from "../../assets/characters/C_Volcanologist_Blue_Wave.png";
import BadgeImage from "../../assets/badges/UI_Sound_Wizard_Badge_Diamond.png";
import bgImage from "../../assets/backgrounds/BG_Default.png";
import profileSwitchIcon from "../../assets/icons/UI_Profile_Switch.png";
import badgeProfileIcon from "../../assets/icons/UI_Badge_Profile_icon.png";

import PreviousArrowButton from "../../components/backpacks/BackArrowButton";
import GreenTitleBar from "../../components/backpacks/GreenTitleBar";
import highlightedImage from "../../components/backpacks/HighlightImage.js";
import highlightedBox from "../../components/backpacks/HighlightBox.js";

import * as styles from "../../assets/ts-styles/badge-profile-styles.ts";

const BadgesContainers = ({ int, style, showTutorial }) => {
  // TODO have an array of actual badge images here
  return (
    <Box
      sx={{
        ...styles.badgeContainersStyle,
      }}
    >
      {Array.from({ length: int }, (_, index) => (
        <img key={index} src={BadgeImage} style={style} alt="Badge" />
      ))}
    </Box>
  );
};

const BadgeAndProfile = ({
  showTutorial,
  profileHighlighted,
  characterSwitchHighlighted,
  badgeBoxHighlighted,
  firstBadgeHighlighted,
  tabHighlighted,
}) => {
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box>
      <GreenTitleBar
        icon={badgeProfileIcon}
        title={"Badges and Profile"}
      />
      <Box sx={styles.parentBox}>
        {!showTutorial && (
          <PreviousArrowButton // TODO not linked correctly
          />
        )}
        <Box
          sx={{
            ...styles.badgeBox,
            ...(badgeBoxHighlighted && highlightedBox),
          }}
        >
          {/* content in tab aka the area that show the badges */}
          <TabContext value={value}>
            <TabPanel value="1" sx={styles.tabBodyStyle}>
              <BadgesContainers
                int={5}
                style={styles.starBadgeStyle}
                showTutorial={!showTutorial}
              />
            </TabPanel>

            <TabPanel value="2" sx={styles.tabBodyStyle}>
              <BadgesContainers
                int={11}
                style={styles.moduleBadgeStyle}
                showTutorial={!showTutorial}
              />
            </TabPanel>

            <TabPanel value="3" sx={styles.tabBodyStyle}>
              <BadgesContainers
                int={4}
                style={styles.unitBadgeStyle}
                showTutorial={!showTutorial}
              />
            </TabPanel>

            <TabPanel value="4" sx={styles.tabBodyStyle}>
              <BadgesContainers
                int={2}
                style={styles.otherBadgeStyle}
                showTutorial={!showTutorial}
              />
            </TabPanel>

            {/* tab name */}
            <TabList
              variant="fullWidth"
              onChange={handleChange}
              aria-label="lab API tabs example"
              TabIndicatorProps={{
                style: { display: "none" },
              }}
              sx={{
                "&& .Mui-selected": {
                  ...styles.tabSelectedStyle
                },
              }}
            >
              <Tab
                label="Stars"
                value="1"
                disableRipple
                sx={styles.tabStyles}
              />
              <Tab
                label="Modules"
                value="2"
                disableRipple
                sx={{
                  ...styles.tabStyles,
                  background: tabHighlighted ? "#FFC702" : "white",
                }}
              />
              <Tab
                label="Units"
                value="3"
                disableRipple
                sx={styles.tabStyles}
              />
              <Tab
                label="Others"
                value="4"
                disableRipple
                sx={styles.tabStyles}
              />
            </TabList>
          </TabContext>
        </Box>

        {/* profile box */}
        <Box
          sx={{
            ...styles.profileBox,
            ...(profileHighlighted && highlightedBox),
          }}
        >
          <img
            src={bgImage}
            style={{
              // TODO temp image need to change
              width: "100%",
              height: "100%",
              objectFit: "cover",
              position: "absolute",
            }}
            alt="Background"
          ></img>
          <img
            src={profileSwitchIcon}
            style={{
              right: "5%",
              bottom: "3%",
              position: "absolute",
              ...(characterSwitchHighlighted && highlightedImage),
            }}
            alt="Profile icon"
          ></img>
          <Box
            sx={{
              position: "absolute",
              width: "100%",
              overflow: "hidden",
            }}
          >
            <Box sx={{ position: "relative" }}>
              <Box sx={styles.blueRectangleStyle}>Name</Box>
              <img
                src={ProfileImage}
                style={{
                  // TODO temp image need to change
                  objectFit: "contain",
                  width: "100%",
                }}
                alt="Profile"
              ></img>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default BadgeAndProfile;
