/**
 * Written by Linh
 */

export const scrollableLessonsStyle = {
    height: "calc(var(--screen-height) * 0.60)",
    width: "calc(var(--screen-width) * 0.80)",
    backgroundColor: "white",
    border: "calc(var(--screen-width) * 0.007) solid black",
    borderRadius: "calc(var(--screen-width) * 0.005)",
    overflowY: "hidden", // hidden
    overflowX: "scroll",
    scrollbarColor: "#C2D2A3 transparent",
    whiteSpace: "nowrap",
    display: "flex",
    margin: "calc(var(--screen-height) * 0.18) auto",
};

export const iconBoxStyle = {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    fontFamily: "Fredoka, sans-serif",
    fontSize: "calc(var(--screen-height) * 0.05)",
    fontWeight: 600,
    textAlign: "center",
    whiteSpace: "pre-wrap",
    margin: "auto",
};

export const tutorialStyle = {
    height: "calc(var(--screen-height) * 0.47)",
    margin: "calc(var(--screen-height) * 0.15) auto",
};

