/**
 * Written by Alex
 */

import React from "react";
import Content from "../components/contentPages/Content.js";
import MainHeader from "../components/contentPages/MainHeader.js";
import MainFooter from "../components/contentPages/MainFooter.js";
import { globalContext } from "../Context.js";

export default function Preview({ state }) {
  let slideId = null;
  let setSlideId = null;
  let setState = null;
  let lessonId = null;
  let setLessonId = null;
  return (
    <div>
      <globalContext.Provider
        value={{ slideId, setSlideId, state, setState, lessonId, setLessonId }}
      >
        <MainHeader
          render={state.headerText !== undefined}
          showButtons={!state.hideHeaderIcons}
          topicText={state.headerText}
        />

        {state.slideElements !== undefined ? (
          state.slideElements.map((element) => (
            <Content content={element} key={element._id} />
          ))
        ) : (
          <></>
        )}

        <MainFooter
          render={state.footerText !== undefined}
          showLeftButton={state.prevSlide !== undefined}
          showRightButton={state.nextSlide !== undefined}
          nameText={state.characterName}
          mainText={state.footerText}
          returnUnit={state.returnUnit !== undefined ? state.returnUnit : null}
          // hintText={state.footerHintText}
        />
      </globalContext.Provider>
    </div>
  );
}
