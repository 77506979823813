/**
 * Written by Alex
 */

import React from "react";

// TODO: ConditionalHighlight is intended to be used with any element, but <mark> only works with text.
// Update to make it work with other things
const ConditionalHighlight = ({ mark, children }) => {
  return mark ? <mark>{children}</mark> : children;
};
export default ConditionalHighlight;
