/**
 * Written by Linh
 */

import { useContext, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { UserContext } from "../../ultilities/user.context";

import IconPassword from "../../assets/icons/password-lock-icon.png";
import IconSchool from "../../assets/icons/school-icon.png";
import IconStudentId from "../../assets/icons/student-book-icon.png";
import IconCredit from "../../assets/icons/UI_Credits_Default.png";
import MitosTitle from "../../assets/titles/title-mitos.png";
import "../../assets/styles/auth-styles.css";

import UniversalButton from "../../ultilities/Buttons";
import { InputField } from "../../ultilities/inputFields";

import { PopUp } from "../../components/display/PopUp";

export default function SignIn() {
  const navigate = useNavigate();
  const location = useLocation();

  const { emailPasswordLogin } = useContext(UserContext);

  const [schoolInfo, setSchoolInfo] = useState("");
  const [studentId, setStudentId] = useState(""); // state for student ID
  const [password, setPassword] = useState(""); // state for password

  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");

  const handleSchoolInfoChange = (event) => setSchoolInfo(event.target.value);
  const handleStudentIdChange = (event) => {
    setStudentId(event.target.value);
  };
  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const inputFields = [
    {
      label: "School Name",
      type: "text",
      iconSrc: IconSchool,
      value: schoolInfo,
      onChange: handleSchoolInfoChange,
    },
    {
      label: "Student ID",
      type: "text",
      iconSrc: IconStudentId,
      value: studentId,
      onChange: handleStudentIdChange,
    },
    {
      label: "Password",
      type: "password",
      iconSrc: IconPassword,
      value: password,
      onChange: handlePasswordChange,
    },
  ];

  const signInPressed = async (event) => {
    event.preventDefault();
    if (schoolInfo === "" || studentId === "" || password === "") {
      setPopupMessage("Make sure to fill out all fields!");
      setShowPopup(true);
    } else {
      event.preventDefault();
      try {
        const loggedInUser = await emailPasswordLogin(studentId, password);
        if (loggedInUser) {
          let isOnboarding = fetchUserOnboardingStatus(loggedInUser.id);
          if (Boolean(isOnboarding) === true) {
            navigate("/tutorialPage")
          } else {
            navigate("/returnUser")
          }
        }
      } catch (error) {
        // email or password is incorrect
        if (error.statusCode === 401) {
          setPopupMessage("Invalid student ID or password!");
          setShowPopup(true);
        } else {
          alert(error);
        }
      }
    }
  };

  const fetchUserOnboardingStatus = async (userId) => {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_HOST}api/getUser?userId=${userId}`
    );
    const data = await response.json();
    return data[0].onboarding;
  };

  const handleCreditsPressed = () => {
    navigate("/credits");
  };

  return (
    <div className="background high-grass-bg">
      <div className="auth-container">
        <img src={MitosTitle} alt="MITOS Title" className="mitos-title" />
      </div>
      <form onSubmit={signInPressed}>
        <div className="section-container">
          {inputFields.map((field) => (
            <InputField key={field.label} {...field} />
          ))}
        </div>

        {/* forgot password */}
        <div className="section-container forgot-password">
          <Link
            to="/system-message"
            className="no-underline"
            state={{ message: "Check your email to reset your password!" }}
          >
            Forgot password?
          </Link>
        </div>

        {/* sign in button */}
        <div className="section-container">
          <div className="button-container">
            <UniversalButton label="Sign In" buttonStyle="" />
          </div>
        </div>

        {/* don't have account */}
        <div className="section-container no-account">
          <span>Don't have an account? </span>
          <Link to="/signup" className="no-underline">
            Sign up
          </Link>
        </div>
      </form>
      <div className="credit-container">
        <button className="credit-btn" onClick={handleCreditsPressed}>
          <img src={IconCredit} alt="Credit icon" className="credit-icon" />
        </button>
      </div>
      {showPopup && (
        <PopUp message={popupMessage} setShowPopup={setShowPopup} />
      )}
    </div>
  );
}
