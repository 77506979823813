/**
 * Written by Linh
 * Use to convert screen sizes in figma format to vh and vw
 */

import React, { useState } from "react";

export default function Converter() {
  const [values, setValues] = useState("");
  const handleValuesChange = (event) => {
    setValues(event.target.value);
  };
  const convertValues = (event) => {
    // event.preventDefault();

    // split the CSS string by semicolons and remove empty entries
    const styles = values.split(";").filter((style) => style.trim() !== "");

    const styleObject = {};

    for (const style of styles) {
      // split each style by colon
      const [key, value] = style.trim().split(":");
      const cleanKey = key.replace(/["']/g, "").trim();

      // extract the numeric part from the value using a regular expression
      let cleanValue = value.trim().replace(/,/g, "").match(/-?\d+(\.\d+)?/)?.[0] || value.trim();

      styleObject[cleanKey] = cleanValue;
    }

    const orderedKeys = ["top", "left", "width", "height"];

    // formatted string in the specified order
    const formattedString = orderedKeys
      .filter((key) => key in styleObject)
      .map((key) => `"${key}": ${styleObject[key]}`)
      .join(", ");
    navigator.clipboard.writeText(formattedString).then(
      () => {
        console.log("Successfully copied to clipboard!");
      },
      () => {
        console.error("Failed to copy to clipboard!");
      }
    );
    console.log(formattedString);
  };

  return (
    <div>
      <form onSubmit={convertValues}>
        <input
          placeholder="input for positions"
          type="string"
          value={values}
          onChange={handleValuesChange}
          className="input"
          style={{ height: "100px", width: "100vw", margin: "auto"}}
        />
      </form>
    </div>
  );
}
