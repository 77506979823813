/**
 * Written by Linh
 */

import React, { useEffect } from "react";
import useSound from "use-sound";
import "../../../assets/styles/display-styles.css";


// parent check mark at the end of every lesson
export default function ParentCheckBox({ checked, setSelectorReturnValue }) {
  let checkedBox = "";
  const [audio] = useSound(
    process.env.REACT_APP_BACKEND_HOST + "audio/Parent-Checkmark.mp3",
    { volume: 0.25, loop: false }
  );

  if (checked) {
    checkedBox = process.env.REACT_APP_BACKEND_HOST + "ui/Checked_Box.png";
  } else {
    checkedBox = process.env.REACT_APP_BACKEND_HOST + "ui/White_Blank.png";
  }

  const handleClickCheckBox = () => {
    checked = !checked;
    audio(); // play jingle sound
    setSelectorReturnValue("checked");
  };

  useEffect(() => {}, [checked]);

  return (
    <div className="checkbox">
      <img
        src={checkedBox}
        alt="Checked Box"
        onClick={handleClickCheckBox}
      />
    </div>
  );
}
