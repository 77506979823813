/**
 * Written by Linh
 */

import React from "react";
import { BACKEND_LINK } from "../../ultilities/global.links.js";
import "../../assets/styles/display-styles.css";

// use when there's slide with new vocab on the post it
export default function VocabPostIt({ text }) {
  const topText = text[0];
  const bottomText1 = text[1];
  const bottomText2 = text[2];
  return (
    <div>
      <div className="post-it-container">
        <img src={`${BACKEND_LINK}img/P_Postit_1.png`} alt="Post-it" />
        <div className="speech-icon">
          <img src={`${BACKEND_LINK}ui/UI_Speech_Bubble_Black_Icon.png`} alt="Speaker button" />
        </div>
        <p className="vocab-text top-text">{topText}</p>
        <p className="vocab-text bottom-text-1">{bottomText1}</p>
        <p className="vocab-text bottom-text-2">{bottomText2}</p>
      </div>
      <img
        src={`${BACKEND_LINK}ui/UI_Pencil_Rotated.png`}
        alt="Pencil"
        className="pencil"
      />
    </div>
  );
}
