/**
 * Written by Alex
 */

import { createContext } from "react"

// global context state is loaded when this page is loaded (once)
// add to this when we need a new kind of slide
export const globalContext = createContext({})

// state includes:
// header shown
// main header topic text
// special header (what is this for??)
// footer shown
// whether buttons shown on footer
// footer name text
// footer main text
// footer hint text
// list of elements currently on screen