/**
 * Written by Linh and Ronghua
 */

import { useState } from "react";

import { useNavigate } from "react-router-dom";
import LeftButton from "../../assets/buttons/Left_Button.png";
import LeftButtonHovered from "../../assets/buttons/Left_Button_Hovered.png";

const BackArrowButton = ({ render, previousID, previousPageName }) => {
  const navigate = useNavigate();
  const [isLeftButtonHovered, setLeftButtonHovered] = useState(false);

  const handleLeftButtonHover = () => {
    setLeftButtonHovered(true);
  };
  const handleLeftButtonLeave = () => {
    setLeftButtonHovered(false);
  };

  const handleBackArrowClick = () => {
    navigate(-1);
  };

  return (
    <div>
      <img
        src={isLeftButtonHovered ? LeftButtonHovered : LeftButton}
        alt="Back Button"
        className="green-back-button"
        onClick={handleBackArrowClick}
        onMouseEnter={handleLeftButtonHover}
        onMouseLeave={handleLeftButtonLeave}
      />
    </div>
  );
};

export default BackArrowButton;
