/**
 * Written by Alex and Linh
 */

import React from "react";
import useSound from "use-sound";
import { useState, useEffect } from "react";

import { useNavigate, useLocation } from "react-router-dom";
import TitleText from "../components/backpacks/TitleText";

import buttonClick from "../assets/audio/UI_Button-Click-1.mp3";
import "../assets/styles/lesson-selection-styles.css";
import "../assets/styles/backgrounds-styles.css";

import { BACKEND_LINK } from "../ultilities/global.links.js";

// show the lesson name and play button
export default function LessonPage() {
  const location = useLocation();
  const [lessonId] = useState(
    location.state === null
      ? "666358b08060991a53417ac9"
      : location.state.lessonId === undefined
      ? "666358b08060991a53417ac9"
      : location.state.lessonId
  );
  const [unitId] = useState(
    location.state === null
      ? "6663546d8060991a53417aa3"
      : location.state.unitId === undefined
      ? "6663546d8060991a53417aa3"
      : location.state.unitId
  );

  const [lesson, setLesson] = useState({});
  const [unit, setUnit] = useState({});
  const navigate = useNavigate();

  const [playButtonClick] = useSound(buttonClick, { volume: 0.25 });

  const handleButtonClick = () => {
    playButtonClick();
    navigate("../contentPage", {
      state: { slideId: lesson.firstSlide, lessonId: lessonId },
    });
  };

  useEffect(() => {
    fetch(BACKEND_LINK + "api/getLesson/" + lessonId)
      .then((response) => response.json())
      .then((data) => {
        setLesson(data);
        console.log(data);
      });
  }, [lessonId]);

  useEffect(() => {
    fetch(BACKEND_LINK + "api/getUnit/" + unitId)
      .then((response) => response.json())
      .then((data) => {
        setUnit(data);
      });
  }, [unitId]);

  return (
    <div className="background volcano-bg">
      <div className="lesson-page-body">
        <TitleText
          text={
            <>
              {unit.name} <br />
              {"Lesson " + lesson.num + ": " + lesson.name}
            </>
          }
          isLessonTitle={true}
        />
        <button className="lesson-play-button" onClick={handleButtonClick}>
          PLAY
        </button>
      </div>
    </div>
  );
}
