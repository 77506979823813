/**
 * Written by Ronghua
 */

import React from "react";

import "../../assets/styles/title-text-styles.css"; 

// the bold green text with black outline 
const TitleText = ({ text, forBackpackLabels, isLessonTitle }) => {
  return (
    <div>
      {forBackpackLabels ? (
        <div className="backpack-title-text-outer-container">
          <div className="title-text-style">
            <div className="green-text">
              {text}
            </div>
            <div className="black-outline">
              {text}
              </div>
          </div>
        </div>
      ) : (
        <div className={`${isLessonTitle ? "lesson-title-outer-container" : "title-text-outer-container"}`}>
          <div className="title-text-style">
            <div className="green-text">
              {text}
            </div>
            <div className="black-outline">
              {text}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TitleText;
