/**
 * Written by Linh
 */

import { createSlice } from "@reduxjs/toolkit";

const audioSlice = createSlice({
  name: "audio",
  initialState: {
    playing: null
  },
  reducers: {
    playAudio: (state, action) => {
        state.playing = action.payload.id;
    },
    pauseAudio: (state, action) => {
      const { id } = action.payload;
      state.audios = { ...state.audios, [id]: false };
    },
    stopAudio: (state) => {
      state.playing = null; // Stop all audio
    },
  },
});

export const { playAudio, pauseAudio, stopAudio } = audioSlice.actions;
export default audioSlice.reducer;
