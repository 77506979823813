import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { UserContext } from "../../ultilities/user.context";
import IconEmail from "../../assets/icons/email-icon.png";
import IconPassword from "../../assets/icons/password-lock-icon.png";
import IconName from "../../assets/icons/profile-icon.png";
import IconSchool from "../../assets/icons/school-icon.png";
import "../../assets/styles/auth-styles.css";
import CreateAccountTitle from "../../assets/titles/title-create-account.png";

import UniversalButton from "../../ultilities/Buttons";
import { InputField } from "../../ultilities/inputFields";
import { PopUp } from "../../components/display/PopUp";

// Utility function to generate a unique class identifier
const generateClassIdentifier = () => {
  return `class-${Math.random().toString(36).substr(2, 9)}`;
};

export default function SignUp() {
  let isMounted = true;
  const navigate = useNavigate();
  const location = useLocation();

  const { emailPasswordSignup } = useContext(UserContext);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [schoolInfo, setSchoolInfo] = useState("");
  const [studentId, setStudentId] = useState("");
  const [className, setClassName] = useState(""); // For teachers
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isTeacher, setIsTeacher] = useState(false); // Checkbox state
  const [classIdentifier, setClassIdentifier] = useState(""); // Class identifier for validation
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");

  const handleFirstNameChange = (event) => setFirstName(event.target.value);
  const handleLastNameChange = (event) => setLastName(event.target.value);
  const handleSchoolInfoChange = (event) => setSchoolInfo(event.target.value);
  const handleStudentIdChange = (event) => setStudentId(event.target.value);
  const handleClassNameChange = (event) => setClassName(event.target.value);
  const handleEmailChange = (event) => setEmail(event.target.value);
  const handlePasswordChange = (event) => setPassword(event.target.value);
  const handleConfirmPasswordChange = (event) =>
    setConfirmPassword(event.target.value);
  const handleIsTeacherChange = () => setIsTeacher(!isTeacher); // Toggle checkbox
  const handleClassIdentifierChange = (event) =>
    setClassIdentifier(event.target.value); // Handle class identifier change

  const inputFields = [
    {
      label: "First Name",
      type: "text",
      iconSrc: IconName,
      value: firstName,
      onChange: handleFirstNameChange,
    },
    {
      label: "Last Name",
      type: "text",
      iconSrc: IconName,
      value: lastName,
      onChange: handleLastNameChange,
    },
    {
      label: "School Name",
      type: "text",
      iconSrc: IconSchool,
      value: schoolInfo,
      onChange: handleSchoolInfoChange,
    },
    {
      label: isTeacher ? "Class Name" : "Student ID", // Switch label dynamically
      type: "text",
      iconSrc: IconSchool,
      value: isTeacher ? className : studentId, // Bind value accordingly
      onChange: isTeacher ? handleClassNameChange : handleStudentIdChange, // Handle change accordingly
    },
    {
      label: "Class Identifier", // Class identifier for teachers
      type: "text",
      iconSrc: IconSchool,
      value: classIdentifier,
      onChange: handleClassIdentifierChange,
    },
    {
      label: "Password",
      type: "password",
      iconSrc: IconPassword,
      value: password,
      onChange: handlePasswordChange,
    },
    {
      label: "Confirm Password",
      type: "password",
      iconSrc: IconPassword,
      value: confirmPassword,
      onChange: handleConfirmPasswordChange,
    },
    {
      label: "Email",
      type: "email",
      iconSrc: IconEmail,
      value: email,
      onChange: handleEmailChange,
    },
  ];

  const signUpPressed = async (event) => {
    event.preventDefault();
    if (email === "" || password === "" || confirmPassword === "") {
      setPopupMessage("Make sure to fill in all the input fields!");
      setShowPopup(true);
    } else if (password !== confirmPassword) {
      setPopupMessage("Password and confirm password don't match");
      setShowPopup(true);
    } else {
      try {
        // If the user is a student and class identifier is provided
        if (!isTeacher && classIdentifier) {
          const response = await fetch(
            `${process.env.REACT_APP_BACKEND_HOST}api/validateClassIdentifier/${classIdentifier}`,
            {
              method: "GET",
            }
          );
          const data = await response.json();

          if (response.status !== 200) {
            setPopupMessage(data.message); // Class identifier invalid
            setShowPopup(true);
            return;
          }
        }

        // Register the email to MongoDB authentication
        const newUser = await emailPasswordSignup(email, password);

        // Prepare the registration data
        const registrationData = {
          userId: newUser.id,
          accountType: isTeacher ? "teacher" : "student",
          firstName: firstName,
          lastName: lastName,
          school: schoolInfo,
          email: email,
          authenticated: false,
          progress: 0,
          onboarding: true,
          className: "N/A",
          classIdentifier: "N/A",
          latestScore: 0, // TODO: Change later to only be for students
        };

        if (isTeacher) {
          registrationData.className = className;
          registrationData.classIdentifier = generateClassIdentifier(); // Generate class ID
        } else {
          registrationData.studentId = studentId;
          registrationData.classIdentifier = classIdentifier;
        }

        // Send data to the backend
        await fetch(process.env.REACT_APP_BACKEND_HOST + "api/register", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(registrationData),
        });

        // Redirect to email verification
        redirectNow();
      } catch (error) {
        if (error.statusCode === 409) {
          navigate("/system-message", {
            state: {
              message: "Oh no! An account with that email already exists!",
            },
          });
        } else {
          alert(error);
        }
      }
    }
  };

  const redirectNow = () => {
    const redirectTo = location.search.replace("?redirectTo=", "");

    if (isMounted) {
      navigate(redirectTo ? redirectTo : "/system-message", {
        state: { message: "Check your email and verify your account!" },
      });
    }
  };

  useEffect(() => {
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <div className="background">
      <div className="auth-container">
        <img
          src={CreateAccountTitle}
          alt="Create your account"
          className="mitos-title"
        />
      </div>
      <form onSubmit={signUpPressed} className="signup-form">
        {/* input fields */}
        <div className="col-container">
          {inputFields.map((field) => (
            <InputField key={field.label} {...field} />
          ))}
        </div>

        {/* Teacher checkbox */}
        <div className="section-container">
          <label>
            <input
              type="checkbox"
              checked={isTeacher}
              onChange={handleIsTeacherChange}
            />
            Register as a Teacher
          </label>
        </div>

        {/* sign up button */}
        <div className="section-container">
          <div className="button-container">
            <UniversalButton label="Sign Up" buttonStyle="" />
          </div>
        </div>
      </form>
      {showPopup && <PopUp message={popupMessage} setShowPopup={setShowPopup} />}
    </div>
  );
}
