/**
 * Written by Ayaan and Link
 */

import {
  DndContext,
  DragOverlay,
  useDraggable,
  useDroppable,
} from "@dnd-kit/core";
import { useEffect, useState, useRef } from "react";
import useSound from "use-sound";
import { useDispatch, useSelector } from "react-redux";
import ReactPlayer from "react-player";

import "../../assets/styles/matching-styles.css";
import * as link from "../../ultilities/global.links";
import { stopAudio, playAudio } from "../../ultilities/redux.audioSlice";
import buttonClick from "../../assets/audio/UI_Button-Click-1.mp3";

import { IncorrectPopUp } from "../display/PopUp";

function DraggableWord({ id, children }) {
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: id,
  });
  const style = transform
    ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
        zIndex: 1000,
      }
    : undefined;

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...listeners}
      {...attributes}
      className="draggable-wrapper"
    >
      {children}
    </div>
  );
}

function DroppableImage({ id, children, isOccupied, hasSprite }) {
  const { setNodeRef } = useDroppable({
    id: id,
    disabled: isOccupied,
  });

  return (
    <div
      ref={setNodeRef}
      className={`image-dropzone-pair ${hasSprite ? "" : "no-sprite"}`}
    >
      {children}
    </div>
  );
}

function Matching({
  options,
  message,
  audioSource,
  setSelectorReturnValue,
  isSpanish,
  behavior,
}) {
  const [items, setItems] = useState([]);
  const [activeId, setActiveId] = useState(null);
  const [showIncorrectPopup, setShowIncorrectPopup] = useState(false);

  // for audio
  const dispatch = useDispatch();
  const incorrectVORef = useRef(null);
  const playing = useSelector((state) => state.audio.playing);

  const [playIncorrectSFX] = useSound(link.INCORRECT_SOUND, {
    volume: 0.25,
    loop: false,
  });

  const [playButtonClick] = useSound(buttonClick, { volume: 0.25 });

  // Fisher-Yates shuffle algorithm
  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  useEffect(() => {
    const newItems = options.map((item, index) => {
      const [englishName, spanishName] = item.name.split("/");
      return {
        id: `item-${index}`,
        location: "outside",
        word: isSpanish ? spanishName.trim() : englishName.trim(),
        media: process.env.REACT_APP_BACKEND_HOST + item.media,
      };
    });
    setItems(shuffleArray([...newItems]));
    setShowIncorrectPopup(false);
  }, [options, isSpanish]);

  useEffect(() => {
    // TODO: rework on this
    function logScreenSize() {
      console.log(`Screen size: ${window.innerWidth} x ${window.innerHeight}`);
    }

    // Log initial screen size
    logScreenSize();

    // Set up event listener for resize
    window.addEventListener("resize", logScreenSize);

    // Clean up the event listener on component unmount
    return () => window.removeEventListener("resize", logScreenSize);
  }, []);

  const handleDragStart = (event) => {
    setActiveId(event.active.id);
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;
    playButtonClick();

    if (over && over.id !== "outside") {
      setItems((prevItems) => {
        const newItems = prevItems.map((item) => {
          if (item.id === active.id) {
            return { ...item, location: over.id };
          }
          if (item.location === over.id) {
            return { ...item, location: "outside" };
          }
          return item;
        });

        // Check if all matches are correct
        const allCorrect = newItems.every((item) => {
          if (item.location === "outside") return true;
          const [englishName, spanishName] = item.location.split("/");
          const correctName = isSpanish
            ? spanishName.trim()
            : englishName.trim();
          return item.word === correctName;
        });

        if (
          allCorrect &&
          newItems.every((item) => item.location !== "outside")
        ) {
          // Move to next slide or change footer text based on behavior
          setTimeout(() => {
            if (behavior === "changeFooterText") {
              setSelectorReturnValue("changeFooterText");
            } else {
              setSelectorReturnValue("correctAnswer");
            }
          }, 0); // Delay to allow sound to play
        } else if (newItems.every((item) => item.location !== "outside")) {
          setShowIncorrectPopup(true);
          playIncorrectSFX();

          // audio handles
          // stop slide vo and update redux state for feedback VO

          if (playing === "slideVO") {
            dispatch(stopAudio());
            dispatch(playAudio({ id: "incorrectFeedbackVO" }));
          }

          // Reset incorrect items and shuffle
          return shuffleArray(
            newItems.map((item) => {
              const [englishName, spanishName] = item.location.split("/");
              const correctName = isSpanish
                ? spanishName.trim()
                : englishName.trim();
              return item.word !== correctName
                ? { ...item, location: "outside" }
                : item;
            })
          );
        }

        return newItems;
      });
    } else {
      setItems((prevItems) =>
        prevItems.map((item) =>
          item.id === active.id ? { ...item, location: "outside" } : item
        )
      );
    }

    setActiveId(null);
  };

  const renderWord = (item) => (
    <DraggableWord key={item.id} id={item.id}>
      <div
        className={
          item.location !== "outside" ? "dropped-item" : "draggable-item"
        }
      >
        {item.word}
      </div>
    </DraggableWord>
  );

  const renderPlaceholder = (key) => (
    <div key={key} className="placeholder-item draggable-item">
      {/* Transparent placeholder */}
    </div>
  );

  return (
    <>
      <div className="matching-container">
        <DndContext onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
          <div className="draggables">
            {items.map((item) =>
              item.location === "outside"
                ? renderWord(item)
                : renderPlaceholder(item.id)
            )}
          </div>
          <div className="droppables">
            {options.map((option) => (
              <DroppableImage
                key={option.name}
                id={option.name}
                isOccupied={items.some((i) => i.location === option.name)}
                hasSprite={option.media && option.media.trim() !== ""}
              >
                {option.media && option.media.trim() !== "" && (
                  <img
                    src={process.env.REACT_APP_BACKEND_HOST + option.media}
                    alt={option.name}
                    className="static-image"
                  />
                )}
                <div className="droppable-item">
                  {items.find((i) => i.location === option.name) ? (
                    renderWord(items.find((i) => i.location === option.name))
                  ) : (
                    <div className="drop-placeholder"></div>
                  )}
                </div>
              </DroppableImage>
            ))}
          </div>
          <DragOverlay dropAnimation={null}>
            {activeId ? (
              <div className="drag-overlay">
                {renderWord(items.find((item) => item.id === activeId))}
              </div>
            ) : null}
          </DragOverlay>
        </DndContext>

        {/* incorrect popup */}
        {showIncorrectPopup && (
          <IncorrectPopUp
            isSpanish={isSpanish}
            setShowPopup={setShowIncorrectPopup}
          />
        )}
      </div>

      {/* default incorrect VO */}
      <ReactPlayer
        url={isSpanish ? link.INCORRECT_ES : link.INCORRECT_EN}
        playing={playing === "incorrectFeedbackVO"}
        ref={incorrectVORef}
      />
    </>
  );
}

export default Matching;
