/**
 * Written by Linh
 * Delete later
 */

import React, { useContext, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { UserContext } from "../../ultilities/user.context";

// assets
import "../../assets/styles/auth-styles.css";
import Cloud from "../../assets/icons/cloud-icon.png";
import UniversalButton from "../../ultilities/Buttons.js";

export default function FakeHome() {
  const location = useLocation(); // Get location object
  const navigate = useNavigate(); // Get the navigation function

  const [currentUser, setUser] = useState([]);

  const { logOutUser } = useContext(UserContext);

  const handleLogout = async () => {
    try {
      const loggedOut = await logOutUser();
      if (loggedOut) {
        navigate("/signin"); // Redirect to the sign-in path
      }
    } catch (error) {
      alert(error);
    }
  };

  useEffect(() => {
    const userId = "667b4cb6523c2367b633cdda";
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_HOST}api/getUser?userId=${userId}`);
        const data = await response.json();
        setUser(data[0]);
      } catch (error) {
        throw error;
      }
    };
    fetchData();
  }, []);

  return (
    <div className="background">
      <div className="container" id="container-cloud">
        <img src={Cloud} alt="Cloud background" className="cloud" />
        <p className="cloud-text inside-container">Hello {currentUser.firstName}!</p>
      </div>
      <div className="button-container">
        <UniversalButton label="Logout" buttonStyle="" onClick={handleLogout} />
      </div>
    </div>
  );
}
