/**
 * Written by Ronghua
 * caluates the smaller fix size ratios
 */

import { useState, useEffect } from "react";

const useScreenSize = () => {
  var newHeight = window.innerHeight;
  var newWidth = window.innerWidth;
  if (window.innerWidth / window.innerHeight > 20 / 13) {
    newHeight = window.innerHeight * 0.9;
    newWidth = (20 * newHeight) / 13;
  } else {
    newWidth = window.innerWidth * 0.9;
    newHeight = (13 * newWidth) / 20;
  }

  const [screenSize, setScreenSize] = useState({
    width: newWidth,
    height: newHeight,
    screenWidth: window.innerWidth,
    screenHeight: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      // ratio width, height = 20 : 13
      if (window.innerWidth / window.innerHeight > 20 / 13) {
        var newHeight = window.innerHeight * 0.9;
        var newWidth = (20 * newHeight) / 13;
        setScreenSize({
          width: newWidth,
          height: newHeight,
          screenWidth: window.innerWidth,
          screenHeight: window.innerHeight,
        });
      } else {
        newWidth = window.innerWidth * 0.9;
        newHeight = (13 * newWidth) / 20;
        setScreenSize({
          width: newWidth,
          height: newHeight,
          screenWidth: window.innerWidth,
          screenHeight: window.innerHeight,
        });
      }
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return screenSize;
};

export default useScreenSize;
