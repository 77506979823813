/**
 * Written by Alex, Linh and Ronghua
 */

import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import CheckedBox from "../assets/buttons/Checked_Box.png";
import UncheckedBox from "../assets/buttons/White_Blank.png";

import BackArrowButton from "../components/backpacks/BackArrowButton";
import GreenTitleBar from "../components/backpacks/GreenTitleBar";

import RightButton from "../assets/buttons/Right_Button.png";
import tocIcon from "../assets/icons/UI_TOC_Icon.png";
import "../assets/styles/toc-styles.css";
import "../assets/styles/backgrounds-styles.css";

// styles and functions for each topic shown in the content page
const TopicListInstance = ({
  completed,
  available,
  nameOfTopic,
  topicNumber,
  slideId,
  lessonId,
}) => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    // TODO specify slideid
    navigate("../ContentPage", {
      state: { slideId: slideId, lessonId: lessonId },
    });
  };

  // navigate user to corresponding lesson page
  const handleButtonTitleClick = () => {
    navigate("../LessonPage", { state: { lessonId: lessonId } });
  };

  const handleButtonClickNone = () => {};

  // display if the lesson is completed or not
  const CheckBox = ({ checked }) => {
    return (
      <div>
        <img
          src={checked ? CheckedBox : UncheckedBox}
          alt="Checked Box"
          className="checkbox-toc"
        />
      </div>
    );
  };

  return (
    <div>
      <div
        className="topic-list-instance"
        style={{ opacity: available ? "1" : ".3" }}
      >
        <img
          src={RightButton}
          alt="Right Button"
          style={{ cursor: available ? "pointer" : "auto" }}
          onClick={
            available
              ? topicNumber === 0
                ? handleButtonTitleClick
                : handleButtonClick
              : handleButtonClickNone
          }
        />

        <div className="topic-text">{topicNumber}</div>

        <div className="dots-spacing"></div>

        <div className="topic-text">{nameOfTopic}</div>

        <CheckBox checked={completed} />
      </div>
    </div>
  );
};

export default function TOCPage({ showTutorial }) {
  const location = useLocation();
  const [lessonId, setLessonId] = useState(
    location.state === null
      ? "666358b08060991a53417ac9"
      : location.state.lessonId === undefined
      ? "666358b08060991a53417ac9"
      : location.state.lessonId
  );
  const [state, setState] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    fetch(process.env.REACT_APP_BACKEND_HOST + "api/getLesson/" + lessonId)
      .then((response) => response.json())
      .then((data) => {
        setState(data);
      });
  }, [lessonId]);

  let titleLength = ("Table of Content: " + state.name).length;

  return (
    <div className="background high-grass-bg">
      {!showTutorial && <BackArrowButton showLeftButton={true} />}

      <GreenTitleBar
        icon={tocIcon}
        title={"Table of Content: " + state.name}
        boxWidth={`${titleLength + 3}ch`}
      />

      <div
        className={`scrollable-topic-list ${
          showTutorial ? "tutorial-toc" : ""
        }`}
      >
        {/* show list of topics */}
        {state.topics !== undefined ? (
          state.topics.map((element, index) => (
            <TopicListInstance
              completed={false}
              available={true}
              nameOfTopic={element.name}
              topicNumber={index}
              slideId={element.slideId}
              lessonId={lessonId}
            />
          ))
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
