/**
 * Written by Alex and Ronghua
 */

import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import PreviousArrowButton from "../components/backpacks/BackArrowButton";
import ContentListing from "../components/backpacks/ContentListing";
import TitleText from "../components/backpacks/TitleText";

import "../assets/styles/lesson-selection-styles.css";
import "../assets/styles/tutorials-adjustments-styles.css";

export default function LandingPage({ showTutorial }) {
  const location = useLocation();
  const [state, setState] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    fetch(process.env.REACT_APP_BACKEND_HOST + "api/getModules/")
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setState({ data: data });
      });
  }, []);

  return (
    <div className="background high-grass-bg">
      <div className="scrollable-page-body">
        {!showTutorial && <PreviousArrowButton showLeftButton={true} />}

        <TitleText
          text={"Module Selection"}
          forBackpackLabels={showTutorial ? true : false}
        />

        <div
          className={`scrollable-item-selection ${
            showTutorial ? "module-tutorial" : ""
          }`}
        >
          {state.data !== undefined ? (
            state.data.map(
              (
                module // TODO place holder for the units
              ) => (
                <ContentListing
                  completed={false} // Completed is whether the player has finished the lesson, its not implemented yet
                  available={module.available} // TODO
                  nameOfTopic={module.name}
                  lessonThumbnail={module.thumbnail}
                  scrollingCheck={false}
                  modulesCheck={false}
                  handleButtonClick={() => {
                    navigate("../modulePage", {
                      state: { moduleId: module._id },
                    });
                  }}
                />
              )
            )
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
}
