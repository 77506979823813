/**
 * Written by Linh
 */

export const InputField = ({ label, type, iconSrc, value, onChange }) => {
  return (
    <div className="input-container">
      {iconSrc && <img src={iconSrc} alt={`${label} icon`} className="icon" />}
      <input
        placeholder={label}
        type={type}
        value={value}
        onChange={onChange}
        className="input"
      />
    </div>
  );
};
