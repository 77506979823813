/**
 * Written by Linh
 */

import { useState } from "react";
import TitleText from "./TitleText";

// characters
import CrustyImg from "../../assets/characters/C_CrustA_Speaking.png";
import VolImg from "../../assets/characters/C_Vol_Neutral_b.png";
import BlueImg from "../../assets/characters/C_Volcanologist_Blue_Wave.png";
import GreenImg from "../../assets/characters/C_Volcanologist_Green_Wave.png";
import PinkImg from "../../assets/characters/C_Volcanologist_Pink_Wave.png";

import "../../assets/styles/character-selection-styles.css";
import "../../assets/styles/selection-styles.css";

const characterOptions = [
  { name: "Vol", img: VolImg },
  { name: "Volcanologist Blue", img: BlueImg },
  { name: "Volcanologist Green", img: GreenImg },
  { name: "Volcanologist Pink", img: PinkImg },
  { name: "Crusty", img: CrustyImg },
];

export default function CharacterSelection({ showTutorial }) {
  const [selectedCharacter, setSelectedCharacter] = useState(null);

  function handleCharacterSelection(character, index) {
    console.log(character);
    setSelectedCharacter(index);
  }

  return (
    <div className={`${
        showTutorial ? "backpack-scrollable-page-body" : "scrollable-page-body"
      }`}>
      <TitleText text="Characters" forBackpackLabels={showTutorial ? true : false}/>

      <div
        className={`scrollable-item-selection ${
          showTutorial ? "module-tutorial" : ""
        }`}
      >
        {characterOptions.map((character, index) => (
          <button
            key={index}
            className={`character-btn`}
            onClick={() => {
              handleCharacterSelection(character, index);
            }}
            style={{
              backgroundColor: selectedCharacter === index ? "#A7E4E1" : "", 
            }}
          >
            <div className="">
              <img src={character.img} alt={character.name} />
            </div>
            {character.name}
          </button>
        ))}
      </div>
    </div>
  );
}
