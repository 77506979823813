/**
 * Written by Linh
 */

export const sliderTextStyle = {
    padding: "calc(var(--screen-height) * 0.03) 3%",
    border: "calc(var(--screen-width) * 0.005) solid black",
    width: "60%",
    margin: "calc(var(--screen-height) * 0.07) auto",
    display: "flex",
    backgroundColor: "white",
    borderRadius: "calc(var(--screen-width) * 0.013)",

    color: "black",
    fontFamily: "Fredoka, sans-serif",
    fontWeight: 400,
    textAlign: "center",
    fontSize: "calc(var(--screen-width) * 0.024)",
};

export const horzButtonsParent = {
    display: "flex",
    margin: "calc(var(--screen-height) * 0.05) 10%",
};

export const viewInfoButtonStyle = {
    margin: "auto",
    width: "calc(var(--screen-width) * 0.30)",
    height: "calc(var(--screen-height) * 0.08)",
    backgroundColor: "white",
    border: "calc(var(--screen-width) * 0.005) solid black",
    borderRadius: "calc(var(--screen-width) * 0.013)",
    display: "flex",

    color: "black",
    fontFamily: "Fredoka, sans-serif",
    fontWeight: 400,
    textAlign: "center",
    fontSize: "calc(var(--screen-width) * 0.027)",
};

export const iconStyle = {
    height: "80%",
    margin: "calc(var(--screen-height) * 0.01) calc(var(--screen-width) * 0.03)",
};
