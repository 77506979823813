/**
 * Written by Alex, Linh, Ronghua, and Ayaan
 */

import React, { useEffect } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./App.css";

// auth pages
import { UserProvider } from "./ultilities/user.context.js";
import SignIn from "./pages/authentication/SignIn.js";
import SignUp from "./pages/authentication/SignUp.js";
import Credits from "./pages/Credits.js";
// may delete later
import PrivateRoute from "./pages/authentication/PrivateRoute.js";
import FakeHome from "./pages/authentication/FakeHome";
import SystemMessage from "./pages/authentication/SystemMessage.js";

// main pages
import LandingPage from "./pages/LandingPage.js";
import TOCPage from "./pages/TOCPage.js";
import LessonPage from "./pages/LessonPage.js";
import ModulePage from "./pages/ModulePage.js";
import UnitPage from "./pages/UnitPage.js";
import ContentPage from "./pages/ContentPage.js";

// backpack
import Backpack from "./pages/hub/Backpack.js";
import BadgeAndProfile from "./pages/hub/BadgeAndProfile.js";
import CharacterSelect from "./pages/hub/CharacterSelect.js";
import Setting from "./pages/hub/Setting.js";
import TutorialsPage from "./pages/hub/TutorialPage.js";

// for developer uses
import Converter from "./dev/Converter.js";
import Editor from "./dev/Editor.js";
import Preview from "./dev/Preview.js";

import useScreenSize from "./ultilities/useScreenSize.js";
import MCPictureInBtnTop from "./components/activities/MCPictureTextTop.js";

function App() {
  const screenSize = useScreenSize();

  let root = document.documentElement;
  root.style.setProperty("--screen-height", screenSize.height + "px");
  root.style.setProperty("--screen-width", screenSize.width + "px");

  useEffect(() => {
    // TODO: figure out what this does
    return function cleanup() {
      document.body.classList.remove("landing");
    };
  });

  return (
    <Router>
      <div className="screen-space"> 
        <div className="fixed-ratio"> 
          {/* auth wrapper from mongodb */}
          <UserProvider>
            <Routes>
              {/* authentication */}
              <Route path="/signin" element={<SignIn />} />
              <Route path="/signup" element={<SignUp />} />
              {/* delete later */}
              <Route path="/system-message" element={<SystemMessage />} />
              <Route exact path="/home" element={<FakeHome />} />
              <Route element={<PrivateRoute />} />
            </Routes>
          </UserProvider>
          <Routes>
            {/* maybe home page?? */}
            <Route path="/landingPage" element={<LandingPage />} />

            {/* backpack */}
            <Route path="/tutorialPage" element={<TutorialsPage />} />
            <Route path="/backpack" element={<Backpack />} />
            <Route path="/badgeAndProfile" element={<BadgeAndProfile />} />
            <Route path="/setting" element={<Setting />} />
            <Route path="/credits" element={<Credits />} />
            <Route path="/tocPage" element={<TOCPage />} />
            <Route path="/characterSelect" element={<CharacterSelect />} />

            {/* main pages */}
            <Route path="/lessonPage" element={<LessonPage />} />
            <Route path="/modulePage" element={<ModulePage />} />
            <Route path="/unitPage" element={<UnitPage />} />
            <Route path="/contentPage" element={<ContentPage />} />

            {/* dev uses */}
            <Route path="/editor" element={<Editor />} />
            <Route path="/preview" element={<Preview />} />
            <Route path="/converter" element={<Converter />} />
            
            
            {/* <Route path="/characterSelection" element={<CharacterSelection />} /> */}
  
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
