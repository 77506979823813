/**
 * Written by Alex, Linh and Ronghua
 */

import React from "react";
import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import PreviousArrowButton from "../components/backpacks/BackArrowButton";
import ContentListing from "../components/backpacks/ContentListing";
import TitleText from "../components/backpacks/TitleText";

import "../assets/styles/lesson-selection-styles.css";
import "../assets/styles/tutorials-adjustments-styles.css";
import "../assets/styles/backgrounds-styles.css";

export default function ModulePage({ showTutorial }) {
  const location = useLocation();
  const [moduleId, setModuleId] = useState(
    location.state === null
      ? "6668eb1300fe5e53fd010760"
      : location.state.moduleId === undefined
      ? "6668eb1300fe5e53fd010760"
      : location.state.moduleId
  );
  const [state, setState] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    fetch(process.env.REACT_APP_BACKEND_HOST + "api/getModule/" + moduleId)
      .then((response) => response.json())
      .then((data) => {
        setState(data);
      });
  }, [moduleId]);

  return (
    <div className="background high-grass-bg">
      <div className="scrollable-page-body">
        {!showTutorial && (
          <PreviousArrowButton // TODO not linked correctly
            previousPageName={"landingPage"}
          />
        )}

        <TitleText
          text={state.name}
          forBackpackLabels={showTutorial ? true : false} // TODO change name here
        />

        <div
          className={`scrollable-item-selection ${
            showTutorial ? "module-tutorial" : ""
          }`}
        >
          {/* the list of all module with its icon and title */}
          {state.units !== undefined ? (
            state.units.map((unit) => (
              <ContentListing
                completed={false} // Completed is whether the player has finished the lesson, its not implemented yet
                available={true} // TODO
                nameOfTopic={unit.name}
                lessonThumbnail={unit.thumbnail}
                scrollingCheck={false}
                modulesCheck={false}
                handleButtonClick={() => {
                  navigate("../unitPage", { state: { unitId: unit._id } });
                }}
              />
            ))
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
}
