/**
 * Written by Linh
 */

export const parentBox = {
    display: "flex",
    height: "65%",
    margin: "calc(var(--screen-height) * 0.04) calc(var(--screen-width) * 0.10) 0 calc(var(--screen-width) * 0.08)",
};

export const profileBox = {
    border: "calc(var(--screen-width) * 0.005) solid black",
    borderRadius: "calc(var(--screen-width) * 0.01)",
    width: "35%",
    position: "relative",
    overflow: "hidden",
};

export const badgeBox = {
    width: "60%",
    height: "auto",
    borderRadius: "calc(var(--screen-width) * 0.01)",
    margin: "0 calc(var(--screen-width) * 0.02) 0 calc(var(--screen-width) * 0.02)",
};

export const blueRectangleStyle = {
    margin: "calc(var(--screen-width) * 0.01) calc(var(--screen-width) * 0.02)",
    backgroundColor: "#a7e4e1",
    border: "calc(var(--screen-width) * 0.005) solid black",
    borderRadius: "calc(var(--screen-width) * 0.01)",
    color: "black",
    fontFamily: "Fredoka, sans-serif",
    fontWeight: 600,
    textAlign: "center",
    fontSize: "calc(var(--screen-width) * 0.04)",
};

export const tabStyles = {
    color: "black",
    fontFamily: "Fredoka, sans-serif",
    fontWeight: 500,
    textAlign: "center",
    fontSize: "calc(var(--screen-height) * 0.025)",
    border: "calc(var(--screen-width) * 0.005) solid black",
    borderRadius: "0 0 calc(var(--screen-width) * 0.01) calc(var(--screen-width) * 0.01)",
    background: "white",
};

export const tabBodyStyle = {
    background: "white",
    height: "auto",
    border: "calc(var(--screen-width) * 0.005) solid black",
    borderRadius: "calc(var(--screen-width) * 0.01) calc(var(--screen-width) * 0.01) 0 0",
    borderBottom: "0",
        //width: "40%",

};

export const tabSelectedStyle = {
    border: "calc(var(--screen-width) * 0.005) solid black",
    borderRadius: "0 0 calc(var(--screen-width) * 0.01) calc(var(--screen-width) * 0.01)",
    borderTop: "0",
    color: "black",
}

export const badgeContainersStyle = {
    justifyContent: "center",
    display: "flex",
    flexWrap: "wrap",
    overflow: "hidden",
    height: "calc(var(--screen-height) * 0.52)",
};

export const starBadgeStyle = {
    padding: "calc(var(--screen-height) * 0.01)",
    height: "calc(var(--screen-height) * 0.20)",
    //width: "30%",
    objectFit: "contain",
};

export const moduleBadgeStyle = {
    padding: "calc(var(--screen-height) * 0.01)",
    height: "29%",
    width: "21%",
    objectFit: "contain",
};

export const unitBadgeStyle = {
    padding: "calc(var(--screen-height) * 0.01)",
    height: "45%",
    width: "40%",
    objectFit: "contain",
    margin: "auto",
};

export const otherBadgeStyle = {
    padding: "calc(var(--screen-height) * 0.01)",
    height: "85%",
    width: "40%",
    objectFit: "contain",
    margin: "auto",
};