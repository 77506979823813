/**
 * Written by Linh and Ronghua
 */

import Box from "@mui/material/Box";
import React, { useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { globalContext } from "../../Context.js";

import useScreenSize from "../../ultilities/useScreenSize.js";

// buttons on the right side of header
import BackpackButton from "./buttons/BackpackButton.js";
import EnEsButton from "./buttons/EnEsButton.js";
import SpeakingButton from "./buttons/SpeakingButton.js";
import ParentCheckBox from "./buttons/ParentCheckbox.js";

import "../../assets/styles/main-header-styles.css"

const HelperButtons = () => {
  const screenSize = useScreenSize();
  const parentStyle = {
    display: "block",
    right: "1%",
    width: "32%",
    top: "1.5%",
    position: "absolute",
  };

  const flexLayoutStyle = {
    display: "flex",
    alignItems: "stretch",
    height: screenSize.height / 10 + "px",
  };

  return (
    <Box sx={parentStyle}>
      <Box sx={flexLayoutStyle}>
        <Box sx={{ flexGrow: 1 }}>
          <EnEsButton />
        </Box>
        <Box sx={{ flexGrow: 1 }}>
          <SpeakingButton />
        </Box>
        <Box sx={{ flexGrow: 1 }}>
          <BackpackButton />
        </Box>
      </Box>
    </Box>
  );
};

const MainHeader = ({
  showButtons,
  showCheckBox,
  topicText,
  special,
  setSelectorReturnValue,
  checkmark,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedOption, setSelectedOption] = useState("");
  const { slideId, setSlideId, state, setState, lessonId, setLessonId } =
    useContext(globalContext);
  const screenSize = useScreenSize();

  const handleDropdownChange = (event) => {
    setSelectedOption(event.target.value);
    // Add language setting code here later
  };

  const handleSoundButtonClick = () => {
    console.log("Sound button clicked");
  };

  const handleMenuButtonClick = () => {
    console.log("in header: " + lessonId);
    navigate("../tocPage", { state: { lessonId: lessonId } });
  };

  return (
    < div>
      <div className="title-checkbox">
        <div className="green-rectangle">
          <div className="green-rectangle-text">{topicText}</div>
        </div>
        {/* checkbox for parects, only show on the last slides of the lesson */}
        {showCheckBox && (
          <ParentCheckBox
            checked={checkmark}
            setSelectorReturnValue={setSelectorReturnValue}
          />
        )}
      </div>
      {showButtons && <HelperButtons />}
    </div>
  );
};

export default MainHeader;
