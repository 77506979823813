/**
 * Written by Alex
 */

import React from "react";
import { useState } from "react";
import Preview from "./Preview.js";
import NewWindow from "react-new-window";
import InputNumber from "rc-input-number";

export default function Editor() {
  const [inputs, setInputs] = useState({
    // name: "ES_V_L0_Introduction1",
    background: "bgs/BG_Default.png",
    characterName: "Earth",
    headerText: "Introduction",
    footerText:
      "Hi, I'm Earth! First, let's learn how to play. Press the <mark>blue arrow</mark> to the right to move forward!",
    nextSlide: "665e7b708060991a53417a67",
    lessonId: "6663435a8060991a53417a9d",
    unitId: "6663546d8060991a53417aa3",
    moduleId: "6668eb1300fe5e53fd010760",
    slideElements: [
      //   {
      //     "media": [
      //       "sprites/C_Earth_Happy.png"
      //     ],
      //     "type": "sprite",
      //     "top": 5.29,
      //     "left": 30.42,
      //     "width": 45.83,
      //     "height": 66.11
      //   }
      //
    ],
  });
  const [load, setLoad] = useState(false);
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const handleSubmit = (event) => {
    let params = {
      content: "```" + JSON.stringify(inputs, null, 4) + "```",
    };
    fetch(process.env.REACT_APP_EDITOR_WEBHOOK, {
      method: "POST",
      body: JSON.stringify(params),
      headers: {
        "Content-Type": "application/json",
      },
    });
    fetch(
      process.env.REACT_APP_BACKEND_HOST + "api/updateSlide/" + inputs._id,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(inputs),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        alert(JSON.stringify(inputs));
      });
  };
  const [slideQuery, setSlideQuery] = useState({
    query: "headerText",
    slideQuery: "Introduction",
  });
  const [lessonQuery, setLessonQuery] = useState({
    query: "name",
    lessonQuery: "Introduction!",
  });
  const [unitQuery, setUnitQuery] = useState({
    query: "name",
    unitQuery: "Volcanoes",
  });
  const [slides, setSlides] = useState();
  const [lessons, setLessons] = useState();
  const [units, setUnits] = useState();
  const handleSlideChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setSlideQuery((values) => ({ ...values, [name]: value }));
  };
  const handleSlideSubmit = (event) => {
    fetch(
      process.env.REACT_APP_BACKEND_HOST +
        "api/getSlides/" +
        slideQuery.query +
        "/" +
        slideQuery.slideQuery
    )
      .then((response) => response.json())
      .then((data) => {
        setSlides(data);
      });
  };
  const handleLessonChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setLessonQuery((values) => ({ ...values, [name]: value }));
  };
  const handleLessonSubmit = (event) => {
    fetch(
      process.env.REACT_APP_BACKEND_HOST +
        "api/getLessons/" +
        lessonQuery.query +
        "/" +
        lessonQuery.lessonQuery
    )
      .then((response) => response.json())
      .then((data) => {
        setLessons(data);
      });
  };
  const handleUnitChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setUnitQuery((values) => ({ ...values, [name]: value }));
  };
  const handleUnitSubmit = (event) => {
    fetch(
      process.env.REACT_APP_BACKEND_HOST +
        "api/getUnits/" +
        unitQuery.query +
        "/" +
        unitQuery.unitQuery
    )
      .then((response) => response.json())
      .then((data) => {
        setUnits(data);
      });
  };
  const [curSlideElement, setCurSlideElement] = useState({
    media: ["sprites/C_Earth_Happy.png"],
    type: "sprite",
    top: 44,
    left: 365,
    width: 550,
    height: 550,
  });
  const [existing, setExisting] = useState(false);
  const populateSlide = (event) => {
    fetch(
      process.env.REACT_APP_BACKEND_HOST + "api/getSlide/" + event.target.value
    )
      .then((response) => response.json())
      .then((data) => {
        setInputs(data);
        setLoad(false);
        setLoad(true);
        setExisting(true);
        // console.log(data);
        // console.log(state);
        // console.log(slideId);
        document.body.style.background = data.background;
        console.log(data.background);
        console.log(document.body.style.background);
        console.log(data.hideHeaderIcons);
      });
  };
  return (
    <div>
      <NewWindow>
        <h1>Lesson Slide Creator</h1>
        {existing ? (
          <p>
            WARNING: You are editing an existing slide. Clicking "Submit" will
            update the slide for everyone!
          </p>
        ) : (
          <></>
        )}
        <form>
          <h2>Slide Baseline</h2>
          <label>
            Slide Background Image (
            <a
              href={process.env.REACT_APP_BACKEND_HOST + "bgs/"}
              target="_blank"
            >
              view available
            </a>
            )
            <input
              type="text"
              name="background"
              value={inputs.background || "bgs/BG_Default.png"}
              onChange={handleChange}
            />
          </label>
          <br />
          <label>
            Header Text
            <input
              type="text"
              name="headerText"
              value={inputs.headerText || "Introduction"}
              onChange={handleChange}
            />
          </label>
          <br />
          <label>
            Character Dialogue Name
            <input
              type="text"
              name="characterName"
              value={inputs.characterName || "Earth"}
              onChange={handleChange}
            />
          </label>
          <br />
          <label>
            Dialogue
            <input
              type="text"
              name="footerText"
              value={inputs.footerText || "Hello world!"}
              onChange={handleChange}
            />
          </label>
          <br />
          <label>
            Previous Slide ID (leave blank for none)
            <input
              type="text"
              name="prevSlide"
              value={inputs.prevSlide || ""}
              onChange={handleChange}
            />
          </label>
          <br />
          <label>
            Next Slide ID (leave blank for none)
            <input
              type="text"
              name="nextSlide"
              value={inputs.nextSlide || ""}
              onChange={handleChange}
            />
          </label>
          <br />
          <label>
            Lesson ID
            <input
              type="text"
              name="lessonId"
              value={inputs.lessonId || ""}
              onChange={handleChange}
            />
          </label>
          <br />
          <label>
            Unit ID
            <input
              type="text"
              name="unitId"
              value={inputs.unitId || ""}
              onChange={handleChange}
            />
          </label>
          <br />
          <label>
            Module ID
            <input
              type="text"
              name="moduleId"
              value={inputs.moduleId || ""}
              onChange={handleChange}
            />
          </label>
          <br />
          <input
            type="button"
            value="Preview"
            onClick={() => {
              setLoad(false);
              setLoad(true);
            }}
          />
          <input type="button" value="Submit" onClick={handleSubmit} />
        </form>
        <form>
          <h2>Slide Elements</h2>
          <p>
            <a
              href={process.env.REACT_APP_BACKEND_HOST + "sprites"}
              target="_blank"
            >
              view available sprites
            </a>
            ,{" "}
            <a href={process.env.REACT_APP_BACKEND_HOST + "ui"} target="_blank">
              view available ui
            </a>
          </p>
          <label for="query">Element type</label>
          <select name="type" id="type" onChange={handleSlideChange}>
            <option value="sprite">Sprite</option>
          </select>
          <br />
          {/* TODO change when we get new element types */}
          <label>
            Image path (include relative path)
            <input
              type="text"
              name="media"
              value={curSlideElement.media[0] || ""}
              onChange={(event) => {
                const value = event.target.value;
                setCurSlideElement({
                  ...curSlideElement,
                  media: [value],
                });
              }}
            />
          </label>
          <br />
          <h3>Copy the following from figma (without the "px")</h3>
          <label>
            Top:
            <InputNumber
              min={0}
              max={832}
              step={1}
              name="top"
              value={curSlideElement.top}
              onChange={(val) =>
                setCurSlideElement({
                  ...curSlideElement,
                  top: val,
                })
              }
            />
          </label>
          <label>
            Left:
            <InputNumber
              min={0}
              max={1280}
              step={1}
              name="left"
              value={curSlideElement.left}
              onChange={(val) =>
                setCurSlideElement({
                  ...curSlideElement,
                  left: val,
                })
              }
            />
          </label>
          <label>
            Height:
            <InputNumber
              min={0}
              max={832}
              step={1}
              name="height"
              value={curSlideElement.height}
              onChange={(val) =>
                setCurSlideElement({
                  ...curSlideElement,
                  height: val,
                })
              }
            />
          </label>
          <label>
            Width:
            <InputNumber
              min={0}
              max={1280}
              step={1}
              name="width"
              value={curSlideElement.width}
              onChange={(val) =>
                setCurSlideElement({
                  ...curSlideElement,
                  width: val,
                })
              }
            />
          </label>
          <input
            type="button"
            value="Add to scene"
            onClick={() => {
              setInputs({
                ...inputs,
                slideElements: [...inputs.slideElements, curSlideElement],
              });
            }}
          />
          <input
            type="button"
            value="Undo last"
            onClick={() => {
              setInputs({
                ...inputs,
                slideElements: inputs.slideElements.slice(0, -1),
              });
            }}
          />
          <input
            type="button"
            value="Remove all"
            onClick={() => {
              setInputs({
                ...inputs,
                slideElements: [],
              });
            }}
          />
        </form>
        <form>
          <h2>Search for other slides (click to import)</h2>
          <label for="query">Search by</label>
          <select name="query" id="query" onChange={handleSlideChange}>
            <option value="headerText">Header</option>
            <option value="footerText">Dialogue</option>
            <option value="lessonId">Lesson ID</option>
            <option value="unitId">Unit ID</option>
            <option value="moduleId">Module ID</option>
          </select>
          <input
            type="text"
            name="slideQuery"
            value={slideQuery.slideQuery || ""}
            onChange={handleSlideChange}
          />
          <input type="button" value="Search" onClick={handleSlideSubmit} />
        </form>
        {slides !== undefined ? (
          slides.map((element, index) => (
            <button value={element._id} onClick={populateSlide}>
              {element._id}
            </button>
          ))
        ) : (
          <></>
        )}
        <form>
          <h2>Search for lessons</h2>
          <label for="query">Search by</label>
          <select name="query" id="query" onChange={handleLessonChange}>
            <option value="name">Name</option>
            <option value="num">Number</option>
            <option value="unitId">Unit ID</option>
            <option value="moduleId">Module ID</option>
          </select>
          <input
            type="text"
            name="lessonQuery"
            value={lessonQuery.lessonQuery || ""}
            onChange={handleLessonChange}
          />
          <input type="button" value="Search" onClick={handleLessonSubmit} />
        </form>
        {lessons !== undefined ? (
          lessons.map((element, index) => element._id)
        ) : (
          <></>
        )}
        <form>
          <h2>Search for units</h2>
          <label for="query">Search by</label>
          <select name="query" id="query" onChange={handleUnitChange}>
            <option value="name">Name</option>
            <option value="num">Number</option>
            <option value="moduleId">Module ID</option>
          </select>
          <input
            type="text"
            name="unitQuery"
            value={unitQuery.unitQuery || ""}
            onChange={handleUnitChange}
          />
          <input type="button" value="Search" onClick={handleUnitSubmit} />
        </form>
        {units !== undefined ? (
          units.map((element, index) => element._id)
        ) : (
          <></>
        )}
        <form>
          <h2>Current modules:</h2>
          Earth Science (id: 6668eb1300fe5e53fd010760)
        </form>
        <br />
      </NewWindow>
      {load === true ? <Preview state={inputs} /> : <></>}
    </div>
  );
}
