/**
 * Written by Ronghua
 */

import React from "react";
import { useNavigate } from "react-router-dom";

import backpackIcon from "../../../assets/icons/UI_Backpack.png";
import highlightedImage from "../../backpacks/HighlightImage.js";

export default function BackpackButton({ highlighted }) {
  const navigate = useNavigate();
  const handleBackpackClicked = () => {
    navigate("/backpack");
  };

  return (
    <img
      src={backpackIcon}
      style={{ height: "100%", ...(highlighted && highlightedImage) }}
      alt="Backpack"
      onClick={handleBackpackClicked}
    />
  );
}
