/**
 * Written by Ronghua
 */

import Switch, { switchClasses } from "@mui/joy/Switch";
import Typography from "@mui/joy/Typography";
import React, { useContext } from "react";
import { globalContext } from "../../../Context";
import useScreenSize from "../../../ultilities/useScreenSize";
import highlightedBox from "../../backpacks/HighlightBox.js";

export default function EnEsButton({ highlighted }) {
  const { isSpanish, setIsSpanish } = useContext(globalContext);
  const screenSize = useScreenSize();

  const labelFontStyle = {
    color: "black",
    fontFamily: "Balsamiq Sans, sans-serif",
    fontSize: screenSize.width * 0.035 + "px",
    fontWeight: 400,
    margin: "auto",
  };

  return (
    <Switch
      variant={"outlined"}
      checked={isSpanish}
      onChange={(event) => setIsSpanish(event.target.checked)}
      slotProps={{
        track: {
          children: (
            <React.Fragment>
              <Typography
                component="span"
                level="inherit"
                sx={{ ...labelFontStyle, opacity: "0.4" }}
              >
                EN
              </Typography>
              <Typography
                component="span"
                level="inherit"
                sx={{ ...labelFontStyle, opacity: "0.4" }}
              >
                ES
              </Typography>
            </React.Fragment>
          ),
        },
        thumb: {
          children: isSpanish ? (
            <React.Fragment>
              <Typography component="span" level="inherit" sx={labelFontStyle}>
                ES
              </Typography>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Typography component="span" level="inherit" sx={labelFontStyle}>
                EN
              </Typography>
            </React.Fragment>
          ),
        },
      }}
      sx={{
        "--Switch-trackWidth": Math.round(screenSize.width * 0.15) + "px",
        "--Switch-trackHeight": Math.round(screenSize.height * 0.08) + "px",
        "--Switch-trackRadius": Math.round(screenSize.width * 0.005) + "px",
        "--Switch-trackBorderColor": "#000000",
        "--Switch-trackBackground": "#DFDEC1",

        "--Switch-thumbRadius": "3px",
        "--Switch-thumbWidth": Math.round(screenSize.width * 0.07) + "px",
        "--Switch-thumbSize": Math.round(screenSize.height * 0.07) + "px",
        "--Switch-thumbBackground": "#C2D2A3",
        "--Switch-thumbBorderColor": "#000000",

        "&:hover": {
          "--Switch-thumbBorderColor": "#000000",
          "--Switch-trackBorderColor": "#000000",
          "--Switch-trackBackground": "#DFDEC1",
        },

        [`&.${switchClasses.checked}`]: {
          "--Switch-thumbBackground": "#C2D2A3",
          "--Switch-trackBackground": "#DFDEC1",
          "--Switch-trackBorderColor": "#000000",

          "&:hover": {
            "--Switch-thumbBackground": "#C2D2A3",
            "--Switch-trackBackground": "#DFDEC1",
            "--Switch-trackBorderColor": "#000000",
          },
        },

        border: screenSize.width * 0.004 + "px solid black",
        borderRadius: screenSize.width * 0.01 + "px",
        margin: screenSize.height * 0.005 + "px",
        ...(highlighted && highlightedBox),
      }}
    />
  );
}
