/**
 * Written by Linh
 */

import { useEffect, useState } from "react";
import PreviousArrowButton from "../components/backpacks/BackArrowButton";

import CreditsTitle from "../assets/titles/title-credits.png";
import "../assets/styles/auth-styles.css";
import "../assets/styles/credits-styles.css";
import "../assets/styles/backgrounds-styles.css";

export default function Credits() {
  const [credits, setCredits] = useState([]);

  useEffect(() => {
    // get credit data from backend
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_HOST}api/getCredits`
        );
        const data = await response.json();
        setCredits(data[0].sections);
      } catch (error) {
        throw error;
      }
    };
    fetchData();
  }, []);

  return (
    <div className="background high-grass-bg">
      {/* title */}
      <div className="container">
        <img src={CreditsTitle} alt="Credits Title" className="mitos-title" />
      </div>
      {/* titles and names display */}
      <div className="scrollable-credits">
        {credits.map((section, index) => (
          <div className="credit-container" key={index}>
            <div className="credit-title">{section.title}</div>
            <div>
              {section.names.map((name, i) => (
                <div className="credit-names" key={i}>
                  {name}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>

      {/* back btn */}
      <div className="credit-container">
        <PreviousArrowButton showLeftButton={true} />
      </div>
    </div>
  );
}
