/**
 * Written by Linh
 */

import React from "react";
import "../assets/styles/mark-styles.css";

export default function MarkText({ isSpanish, sentence, textAndStyle }) {
  // to get either english or spanish assigned marked texts
  const getTextVersion = (text) =>
    isSpanish && text.textEs ? text.textEs.trim() : text.text;

  // split the text and insert highlight spans
  const getMarkedText = (sentence, textAndStyle) => {
    let currentIndex = 0;
    const parts = [];

    textAndStyle.forEach((text) => {
      const markText = getTextVersion(text);
      const startIndex = sentence.indexOf(markText, currentIndex);

      // added conditional case to skip when textEs is an empty string
      if (markText === "" || startIndex === -1) return;

      // add the non-highlighted part before the highlighted text
      if (startIndex > currentIndex) {
        parts.push(sentence.substring(currentIndex, startIndex));
      }

      // add the highlighted text with the correct style
      parts.push(
        <span key={startIndex} className={text.style}>
          {markText}
        </span>
      );

      // update currentIndex to continue from after the highlighted text
      currentIndex = startIndex + markText?.length;
    });

    // add any remaining text after the last highlight
    if (currentIndex < sentence.length) {
      parts.push(sentence.substring(currentIndex));
    }

    return parts;
  };
  return <>{getMarkedText(sentence, textAndStyle)}</>;
}
