/**
 * Written by Linh and Ronghua
 */

import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { globalContext } from "../../Context.js";

import Content from "../../components/contentPages/Content.js";
import MainFooter from "../../components/contentPages/MainFooter.js";
import MainHeader from "../../components/contentPages/MainHeader.js";
import BackpackButton from "../../components/contentPages/buttons/BackpackButton.js";
import EnEsButton from "../../components/contentPages/buttons/EnEsButton.js";
import SpeakingButton from "../../components/contentPages/buttons/SpeakingButton.js";

import LandingPage from "../LandingPage.js";
import ModulePage from "../ModulePage.js";
import TOCPage from "../TOCPage.js";
import UnitPage from "../UnitPage.js";
import Backpack from "./Backpack.js";
import BadgeAndProfile from "./BadgeAndProfile.js";
import CharacterSelect from "./CharacterSelect.js";
import Setting from "./Setting.js";
import CharacterSelection from "../../components/backpacks/CharacterSelection.js";

import "../../assets/styles/backgrounds-styles.css";
import "../../assets/styles/tutorial-styles.css";

export default function TutorialsPage() {
  const location = useLocation();
  const [slideId, setSlideId] = useState(
    location.state === null
      ? "667f0c3823d9a1f59f5f98a1"
      : location.state.slideId
  );
  const [state, setState] = useState({
    // defaults
    hideHeaderButtons: false,
    highlightLeftArrow: false,
    highlightRightArrow: false,
  });

  useEffect(() => {
    fetch(process.env.REACT_APP_BACKEND_HOST + "api/getSlide/" + slideId)
      .then((response) => response.json())
      .then((data) => {
        setState(data);
        document.body.style.background = data.background;
      });
  }, [slideId]);

  const HelperButtons = ({
    display,
    enEsHighlighted,
    speakingHighlighted,
    backpackHighlighted,
  }) => {
    return (
      display && (
        // TODO: still need to fix this
        <div className="helper-btn-container">
          <div
            className="helper-btns"
          >
            <div style={{ margin: "auto", paddingRight: "calc(var(--screen-width) * 0.03)"}}>
              <EnEsButton highlighted={enEsHighlighted} />
            </div>
            <div style={{ paddingRight: "calc(var(--screen-width) * 0.08)"}}>
              <SpeakingButton highlighted={speakingHighlighted} />
            </div>
            <div style={{paddingRight: "calc(var(--screen-width) * 0.05)"}}>
              <BackpackButton highlighted={backpackHighlighted} />
            </div>
          </div>
        </div>
      )
    );
  };

  return (
    <div className="background high-grass-bg">
      <globalContext.Provider value={{ state, setState, slideId, setSlideId }}>
        {state.showLandingPage && <LandingPage showTutorial={true} />}
        {state.showModulePage && <ModulePage showTutorial={true} />}
        {state.showUnitPage && <UnitPage showTutorial={true} />}
        {state.showTOCPage && <TOCPage showTutorial={true} />}
        {state.showCharacterSelection && (
          <CharacterSelection showTutorial={true} />
        )}
        {state.showBackpack && (
          <Backpack
            showTutorial={true}
            tutorialHighlighted={state.tutorialHighlighted !== undefined}
            badgeProfileHighlighted={
              state.badgeProfileHighlighted !== undefined
            }
            tocHighlighted={state.tocHighlighted !== undefined}
            modulesHighlighted={state.modulesHighlighted !== undefined}
            settingsHighlighted={state.settingsHighlighted !== undefined}
            signOutHighlighted={state.signOutHighlighted !== undefined}
          />
        )}
        {state.showSetting && (
          <Setting
            showTutorial={true}
            audioHighlighted={state.audioHighlighted !== undefined}
            infoButtonHighlighted={state.infoButtonHighlighted !== undefined}
          />
        )}

        {/* control buttons on top right */}
        <HelperButtons
          display={state.displayHelperButtons !== undefined} // TODO CHANG THIS
          enEsHighlighted={state.enEsHighlighted !== undefined}
          speakingHighlighted={state.speakingHighlighted !== undefined}
          backpackHighlighted={state.backpackHighlighted !== undefined}
        />

        {/* badge and profile */}
        {state.showBadgeAndProfile && (
          <BadgeAndProfile
            showTutorial={true}
            profileHighlighted={state.profileHighlighted !== undefined}
            characterSwitchHighlighted={
              state.characterSwitchHighlighted !== undefined
            }
            badgeBoxHighlighted={state.badgeBoxHighlighted !== undefined}
            firstBadgeHighlighted={state.firstBadgeHighlighted !== undefined}
            tabHighlighted={state.tabHighlighted !== undefined}
          />
        )}

        {/* content page */}
        {state.headerText && (
          <MainHeader
            showButtons={state.displayHeaderButtons !== undefined}
            topicText={state.headerText}
          />
        )}

        {state.slideElements !== undefined ? (
          state.slideElements.map((element) => (
            <Content content={element} key={element._id} />
          ))
        ) : (
          <></>
        )}

        {state.footerText && (
          <MainFooter
            showLeftButton={state.prevSlide !== undefined}
            highlightLeftButton={state.highlightLeftArrow}
            showRightButton={state.nextSlide !== undefined}
            highlightRightButton={state.highlightRightArrow}
            nameText={state.characterName}
            mainText={state.footerText}
            returnUnit={
              state.returnUnit !== undefined ? state.returnUnit : null
            }
          />
        )}
      </globalContext.Provider>
    </div>
  );
}
