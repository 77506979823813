/**
 * Written by Ronghua
 */

export const highlightedBox = {
  boxShadow:
    "6px 6px 0 #FFC702, \
          -6px -6px 0 #FFC702, \
          -6px 6px 0 #FFC702, \
          6px -6px 0 #FFC702, \
          6px 0px 0 #FFC702, \
          -6px 0px 0 #FFC702, \
          0px 6px 0 #FFC702, \
          0px -6px 0 #FFC702",
};

export default highlightedBox;
